/* eslint-disable import/prefer-default-export */
const graphColors = {
  green: '#1b9e77',
  orange: '#da5f03',
  purple: '#7570b4',
  pink: '#e7288a',
  lightGreen: '#66a61f',
  yellow: '#e7ac02',
  brown: '#a6771d',
  grey: '#666666',
};

export const graphSettings = {
  defaultTheme: Object.values(graphColors),
  monochromeColorPalette: [graphColors.green],
  dualColorPalette: [graphColors.green, graphColors.orange],
  quadColorPalette: [
    graphColors.green,
    graphColors.orange,
    graphColors.purple,
    graphColors.pink,
  ],
  margin: {
    // common graph margins
    CountGraph: { top: 20, right: 20, bottom: 80, left: 30 },

    // Property graph margins
    ResponseTimeDistributionGraph: { top: 50, right: 20, bottom: 30, left: 60 },
    AutomationPercentGraph: { top: 40, right: 50, bottom: 30, left: 10 },
    LanguageGraph: { top: 40, right: 50, bottom: 30, left: 10 },
    ProblemSolvedGraph: { top: 40, right: 50, bottom: 30, left: 10 },
    BookingEngineClicks: { top: 50, right: 20, bottom: 80, left: 40 },
    BookingEngineValue: { top: 50, right: 20, bottom: 80, left: 50 },
    ConnectToHumanGraph: { top: 10, right: 50, bottom: 10, left: 10 },
    AverageSatisfactionScoreGraph: { top: 20, right: 20, bottom: 50, left: 40 },
    SatisfactionScoreRangeGraph: { top: 50, right: 10, bottom: 40, left: 50 },
    AvgResponseTimeByHourGraph: { top: 20, right: 10, bottom: 50, left: 50 },
    NoResponseCountByHourGraph: { top: 20, right: 10, bottom: 50, left: 50 },
    TagsGraph: { top: 30, right: 10, bottom: 150, left: 40 },
    TagsHorizontalGraph: { top: 30, right: 10, bottom: 50, left: 40 },

    // Group graphs margin
    GroupResponsesGraph: { top: 50, right: 30, bottom: 150, left: 30 },
    GroupAutomationPercentGraph: { top: 50, right: 30, bottom: 150, left: 30 },
    GroupAverageSatisfactionScoreGraph: {
      top: 50,
      right: 30,
      bottom: 150,
      left: 30,
    },
    GroupProblemSolvedGraph: { top: 50, right: 30, bottom: 150, left: 30 },
    GroupBookingEngineClicks: { top: 50, right: 30, bottom: 150, left: 30 },
    GroupBookingEngineValue: { top: 50, right: 30, bottom: 150, left: 30 },

    // Csat graphs
    CsatResponseRateGraph: { top: 40, right: 40, bottom: 80, left: 10 },
  },
};

import { ResponsiveBar } from '@nivo/bar';
import { graphSettings } from '../../home/common/graphSettings';

const commonProperties = {
  margin: { top: 20, right: 0, bottom: 60, left: 60 },
  animate: true,
  enableSlices: 'x',
};

export default function BarGraph(props) {
  const {
    data = [],
    keys = [],
    indexBy = '',
    maxValue,
    groupMode = '',
    xAxisTickRotation = 0,
    tooltip,
    graphProps = {},
    colors = [...graphSettings.defaultTheme],
  } = props;

  const graphMaxValue = maxValue ? { maxValue } : {};

  return (
    <ResponsiveBar
      {...commonProperties}
      data={data}
      enableLabel={false}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 50, right: 50, bottom: 100, left: 60 }}
      padding={0.3}
      valueScale={{
        type: 'linear',
        min: 0,
        max: (() => {
          if (!data.length) return 10; // Handle empty data case
          const values = data.flatMap((item) => keys.map((key) => item[key]));
          const maximumValue = Math.max(
            ...values.filter((v) => v !== undefined)
          );
          return maximumValue === 0 || Number.isNaN(maximumValue)
            ? 10
            : undefined;
        })(), // Set max to 10 if all values are 0 or undefined
      }}
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisBottom={{
        orient: 'top',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: xAxisTickRotation,
        tickValues: 5,
        legendPosition: 'middle',
        legendOffset: 32,
        ...(xAxisTickRotation === 90 && {
          format: (value) =>
            value.length > 29 ? `${value.slice(0, 26)}...` : value,
        }),
      }}
      {...graphMaxValue}
      {...(groupMode && { groupMode })}
      {...(tooltip && { tooltip })}
      {...graphProps}
    />
  );
}
